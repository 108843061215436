var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"w-full max-w-7xl md:max-w-lg lg:max-w-6xl 2xl:max-w-7xl mx-auto index mt-4 mb-20"},[_c('div',{staticClass:"flex flex-col lg:flex-row gap-x-6"},[_c('div',{staticClass:"min-w-0 w-full lg:static lg:max-h-full lg:overflow-visible "},[(_vm.mainArticle)?_c('div',{staticClass:"w-full bg-white mt-7 ",on:{"click":function($event){return _vm.$router.push(
            '/tallozo/cikk/' + _vm.mainArticle.id + '/' + _vm.mainArticle.slug
          )}}},[_c('div',{staticClass:"flex flex-col lg:flex-row"},[(_vm.mainArticle.images.length || _vm.mainArticle.source.thumbnail)?_c('div',{staticClass:"p-3 w-full lg:w-80 flex place-items-center place-content-center h-full"},[_c('RePictureSet',{staticClass:"h-full w-full",attrs:{"image":_vm.getMainArticleImgObj(),"image-class":"img-fluid w-100 lazyload","sizes":"180px","alt":_vm.mainArticle.title}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-full p-4"},[_c('p',{staticClass:"font-semibold"},[_vm._v("\n              "+_vm._s(_vm._f("removeHtml")(_vm.mainArticle.uptitle))+"\n            ")]),_vm._v(" "),_c('h5',{staticClass:"font-source-serif-pro text-2xl text-red"},[_vm._v("\n              "+_vm._s(_vm.mainArticle.title)+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"font-semibold"},[_vm._v("\n              "+_vm._s(_vm._f("removeHtml")(_vm.mainArticle.subtitle))+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"my-3"},[_vm._v("\n              "+_vm._s(_vm.mainArticle.description)+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.mainArticle.source.name +
                  ", " +
                  _vm.format(
                    _vm.mainArticle.published_at
                      ? _vm.mainArticle.published_at
                      : _vm.mainArticle.created_at,
                    "YYYY. MMMM D."
                  ))+"\n            ")])])])]):_vm._e(),_vm._v(" "),_c('client-only',[_c('div',{staticClass:"flex flex-col lg:flex-row mx-10  lg:mx-auto gap-x-[30px] my-[30px]"},_vm._l((_vm.nowPlaying.data),function(item,index){return _c('now-playing-window',{key:_vm.$unique() + index,attrs:{"song":item,"index":index}})}),1),_vm._v(" "),_c('event-window'),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-idle":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hydrated = ref.hydrated;
return [_c('div',[(hydrated)?_c('article-window'):_vm._e()],1)]}}])}),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-idle":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hydrated = ref.hydrated;
return [_c('div',[(hydrated)?_c('album-window'):_vm._e()],1)]}}])}),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-idle":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hydrated = ref.hydrated;
return [_c('div',[(hydrated)?_c('lyric-window'):_vm._e()],1)]}}])})],1)],1),_vm._v(" "),_c('div',{staticClass:"inset-0 lg:w-1/4 flex flex-col"},[(_vm.findSidebarModule('quote_module') !== null)?_c('QuoteWindow',{class:'w-full mt-3 order-' + _vm.findSidebarModule('quote_module')}):_vm._e(),_vm._v(" "),(_vm.findSidebarModule('knowledge_module') !== null)?_c('KnowledgeWindow',{class:'w-full order-' + _vm.findSidebarModule('knowledge_module')}):_vm._e(),_vm._v(" "),(_vm.findSidebarModule('unique_module_1') !== null && this.$store.state.settings['OPTIONS_INDEX_SIDEBAR_ORDER'].value.unique_module_1)?_c('banner1-window',{class:'w-full order-' + _vm.findSidebarModule('unique_module_1')}):_vm._e(),_vm._v(" "),(_vm.findSidebarModule('unique_module_2') !== null && this.$store.state.settings['OPTIONS_INDEX_SIDEBAR_ORDER'].value.unique_module_2)?_c('banner2-window',{class:'w-full order-' + _vm.findSidebarModule('unique_module_2')}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }