<template>
  <section class="w-full max-w-7xl md:max-w-lg lg:max-w-6xl 2xl:max-w-7xl mx-auto index mt-4 mb-20">
    <!-- <h1 class="text-6xl text-gray-darkest sm:text-red md:text-green lg:text-blue-500 xl:text-yellow 2xl:text-purple-800">
      hello
    </h1> -->
    <div class="flex flex-col lg:flex-row gap-x-6"> 
      <div class="min-w-0 w-full lg:static lg:max-h-full lg:overflow-visible ">
        <!-- mainarticle -->
        <div
          v-if="mainArticle"
          class="w-full bg-white mt-7 "
          @click="
            $router.push(
              '/tallozo/cikk/' + mainArticle.id + '/' + mainArticle.slug
            )
          "
        > 
          <div class="flex flex-col lg:flex-row">
            <div
              v-if="mainArticle.images.length || mainArticle.source.thumbnail"
              class="p-3 w-full lg:w-80 flex place-items-center place-content-center h-full"
            >
              <RePictureSet
                :image="getMainArticleImgObj()"
                image-class="img-fluid w-100 lazyload"
                class="h-full w-full"
                sizes="180px"
                :alt="mainArticle.title"
              />
            </div>
            <div class="w-full p-4">
              <p class="font-semibold">
                {{ mainArticle.uptitle | removeHtml }}
              </p>
              <h5 class="font-source-serif-pro text-2xl text-red">
                {{ mainArticle.title }}
              </h5>
              <p class="font-semibold">
                {{ mainArticle.subtitle | removeHtml }}
              </p>
              <p class="my-3">
                {{ mainArticle.description }}
              </p>
              <p>
                {{
                  mainArticle.source.name +
                    ", " +
                    format(
                      mainArticle.published_at
                        ? mainArticle.published_at
                        : mainArticle.created_at,
                      "YYYY. MMMM D."
                    )
                }}
              </p>
            </div>
          </div>
        </div>
        <client-only>
          <div class="flex flex-col lg:flex-row mx-10  lg:mx-auto gap-x-[30px] my-[30px]"> 
            <now-playing-window
              v-for="(item, index) in nowPlaying.data"
              :key="$unique() + index"
              :song="item"
              :index="index"
            />
          </div>
      
          <!-- EVENT COMPONENT HERE 
        <LazyHydrate
          v-slot="{ hydrated }" 
          when-visible
        >
          <div>
            <event-window 
              v-if="hydrated"
            />
          </div>
        </LazyHydrate>
        -->
          <!-- <LazyHydrate
          v-slot="{ hydrated }" 
          when-visible
        >
          <div> -->
          <!-- 
              v-if="hydrated" -->
          <event-window />
          <!-- </div>
        </LazyHydrate> -->
          <!-- ARTICLE COMPONENT HERE -->
          <LazyHydrate
            v-slot="{ hydrated }"
            when-idle
          >
            <div>
              <article-window 
                v-if="hydrated"
              />
            </div>
          </LazyHydrate>

      
          <!-- ALBUM COMPONENT HERE -->
          <LazyHydrate
            v-slot="{ hydrated }"
            when-idle
          >
            <div>
              <album-window 
                v-if="hydrated"
              />
            </div>
          </LazyHydrate>

          <!-- FORUM COMPONENT HERE -->
          <!-- <LazyHydrate
            v-slot="{ hydrated }"
            when-idle
          >
            <div>
              <forum-window 
                v-if="hydrated"
              />
            </div>
          </LazyHydrate> -->

          <!-- MUSIC ALBUM COMPONENT HERE -->
          <!-- <LazyHydrate
            v-slot="{ hydrated }"
            when-idle
          >
            <div>
              <music-album-window 
                v-if="hydrated"
              />
            </div>
          </LazyHydrate> -->

          <!-- LYRIC COMPONENT HERE -->
          <LazyHydrate
            v-slot="{ hydrated }"
            when-idle
          >
            <div>
              <lyric-window 
                v-if="hydrated"
              />
            </div>
          </LazyHydrate>
        </client-only>

        <!--  <event-window /> 
        <article-window />
        <album-window />
        <forum-window />
        <music-album-window />
        <lyric-window /> -->
      </div>
      <div class="inset-0 lg:w-1/4 flex flex-col">
        <QuoteWindow
          v-if="findSidebarModule('quote_module') !== null"
          :class="'w-full mt-3 order-' + findSidebarModule('quote_module')"
        />
        <KnowledgeWindow
          v-if="findSidebarModule('knowledge_module') !== null"
          :class="'w-full order-' + findSidebarModule('knowledge_module')"
        />
        <banner1-window 
          v-if="findSidebarModule('unique_module_1') !== null && this.$store.state.settings['OPTIONS_INDEX_SIDEBAR_ORDER'].value.unique_module_1"
          :class="'w-full order-' + findSidebarModule('unique_module_1')"
        />
        <banner2-window 
          v-if="findSidebarModule('unique_module_2') !== null && this.$store.state.settings['OPTIONS_INDEX_SIDEBAR_ORDER'].value.unique_module_2"
          :class="'w-full order-' + findSidebarModule('unique_module_2')"
        />
      </div>
    </div>
  </section>
</template>
<script>
import Loader from "~/components/core/loader.vue"
import LazyHydrate from 'vue-lazy-hydration';
import RePictureSet from '~/components/rxt/recomponent/RePictureSet.vue'
import NowPlayingWindow from '~/components/index/now-playing-window.vue'

const EventWindow = () => ({
  component: import('~/components/pages/index/event-window.vue'),
  loading: Loader
})
const ArticleWindow = () => ({
  component: import('~/components/pages/index/article-window.vue'),
  loading: Loader
})
const AlbumWindow = () => ({
  component: import('~/components/pages/index/album-window.vue'),
  loading: Loader
})
// const ForumWindow = () => ({
//   component: import('~/components/pages/index/forum-window.vue'),
//   loading: Loader
// })
// const MusicAlbumWindow = () => ({
//   component: import('~/components/pages/index/music-album-window.vue'),
//   loading: Loader
// })
const LyricWindow = () => ({
  component: import('~/components/pages/index/lyric-window.vue'),
  loading: Loader
})
const QuoteWindow = () => ({
  component: import('~/components/pages/sidebar/QuoteWindow.vue'),
  loading: Loader
})
const KnowledgeWindow = () => ({
  component: import('~/components/pages/sidebar/KnowledgeWindow.vue'),
  loading: Loader
})
const Banner1Window = () => ({
  component: import('~/components/pages/index/sidebar/banner1-window.vue'),
  loading: Loader
})
const Banner2Window = () => ({
  component: import('~/components/pages/index/sidebar/banner2-window.vue'),
  loading: Loader
})

export default {
  components: {
    LazyHydrate,
    EventWindow,
    ArticleWindow,
    AlbumWindow,
    // ForumWindow,
    // MusicAlbumWindow,
    LyricWindow,
    RePictureSet,
    QuoteWindow,
    KnowledgeWindow,
    Banner1Window,
    Banner2Window,
    NowPlayingWindow,
    // VRuntimeTemplate
  },
  head() {
    return {
      titleTemplate: "Folkrádió – népzene az interneten",
      meta: [
        {
          hid: "title",
          name: "title",
          content: "Folkrádió – népzene az interneten"
        },
        {
          hid: "description",
          name: "description",
          content:
            "A Folkrádió Európa első internetes népzenei rádiója, és az egyetlen olyan rádió, amely éjjel-nappal kizárólag népzenét sugároz. Hallgasd a magyar nyelvterület népzenéjét bármikor és bárhol az alkalmazásunk segítségével!"
        },
        {
          hid: "og:title",
          name: "og:title",
          property: "og:title",
          content: "Folkrádió – népzene az interneten"
        },
        {
          hid: "og:description",
          name: "og:description",
          property: "og:description",
          content:
            "A Folkrádió Európa első internetes népzenei rádiója, és az egyetlen olyan rádió, amely éjjel-nappal kizárólag népzenét sugároz. Hallgasd a magyar nyelvterület népzenéjét bármikor és bárhol az alkalmazásunk segítségével!"
        },
        {
          hid: "og:image",
          name: "og:image",
          property: "og:image",
          content: this.$getDomain() + "/img/meta_default.png"
        }
      ]
    };
  },
  filters: {
    removeHtml (value) {
      // const div = document.createElement('div')
      // div.innerHTML = value
      // return div.textContent || div.textContent || ''
      return value !== null ? value.replace(/<[^>]*>?/gm, '') : ''
    }
  },
  data () {
    return {
      mainArticle: null
    };
  },
  computed: {
    nowPlaying() {
      return this.$store.getters['getNowPlaying']
    }
  },
  fetchOnServer: false,
  fetch() {
    this.$axios.get("gleaners?filter[featured]=1&sort=-published_at,-updated_at&limit=1").then(response => {
      this.mainArticle = response.data.data[0];

    }).catch(e =>{
      this.mainArticle = null
    })
  },
  methods: {
    isClient(){
      return process.client;
    },
    time(milliseconds) {
      if (!milliseconds) {
        return "";
      }

      return this.$moment.unix(milliseconds).format("HH:mm");
    },
    refreshPlaylist() {
      this.$axios.get("radio/nowplaying", { useCache: false }).then(response => {
        this.$store.commit('setNowPlaying', response.data)
      });
    },
    today() {
      return this.$moment().format("YYYY-MM-DD");
    },
    format(date, format = "YYYY. MMMM D.") {
      return this.$moment(date, "YYYY-MM-DD").format(format);
    },
    getMainArticleImgObj() {
      if (this.mainArticle.source && this.mainArticle.source.thumbnail) {
        return this.mainArticle.source.thumbnail;
      }
      return null;
    },
    findSidebarModule(name){
      let settings = this.$store.state.settings
      let order = settings['OPTIONS_INDEX_SIDEBAR_ORDER'].value.order;
      let index = order.findIndex(item => item === name)
      return index === -1 ? null : index
    }
  }
};
</script>
