<template>
  <picture v-if="image">
    
    <!-- :data-src="image.nextgen ? image.nextgen : false" -->
    <source
      type="image/webp"
      :sizes="sizes"
      :data-srcset="image.nextgenSrcset ? image.nextgenSrcset : false"
    >
    <!-- <source
      v-if="image"
      :type="image.mime_type"
      :data-src="image.responsive ? image.responsive : false"
      :data-srcset="image.responsiveSrcset ? image.responsiveSrcset : false"
    > -->
    <img
      :data-src="image ? image.url : '/img/event-default_w240.jpg'"
      :data-srcset="image.responsiveSrcset ? image.responsiveSrcset : false"
      :sizes="sizes"
      :class="imageClass"
      :alt="alt"
    >
  </picture>
</template>
<script>
export default {
  props: {
    // eslint-disable-next-line
    image: {
      required: true
    },
    sizes: {
      type: String,
      default: ''
    },
    imageClass: {
      default: '',
      type: String,
    },
    alt: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
img {
  width: 100%;
}
</style>