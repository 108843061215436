
<template>
  <div
    v-if="song.azuracast_data"
    class="w-full lg:w-1/3 bg-white  rounded-2xl border border-white  hover:border-yellow text-center p-[23px] group "
    :class="{ 'shadow-2xl': index == 1,
              'hidden lg:block' : index !==1}"
  >  
    <button
      v-if="index == 1"
      class="absolute ml-[-20px] mt-[-44px] z-10"
    >
      <img
        v-if="!isRadioPlaying"
        src="/img/nowplaying_play_icon.svg"
        class="w-10 h-10"
        title="Rádió indítása"
        alt="Rádió indítása"
        @click="switchRadioStatus()"
      > 
      <img
        v-else
        src="/img/nowplaying_stop_icon.svg"
        class="w-10 h-10"
        title="Rádió indítása"
        alt="Rádió indítása"
        @click="switchRadioStatus()"
      > 
    </button>

    <nuxt-link
      :to="'/mediatar/felvetelek/' + song.id + '/' + song.slug"
      class="flex flex-col h-full"
    >
      <p class="uppercase text-xl font-semibold ">
        <span v-if="index == 0">Előző</span>
        <span v-else-if="index == 1">Most szól</span>
        <span v-else>Következő</span>
      </p>
      <p class="font-source-serif-pro font-semibold ">
        <img
          src="/img/clock_icon.svg"
          class="pr-2 inline absolute ml-[-25px] mt-[3px] z-10"
          alt=""
        > 
        {{
          time(song.azuracast_data.played_at)
        }}
      </p>
  
      <RePictureSet
        v-if="song.album && song.album.thumbnail"
        :image="song.album.thumbnail"
        image-class=" py-[18px] lazyload"
        sizes="350px"
      />
      <div
        v-else-if="song.album && !song.album.thumbnail"
        class="py-32  my-4   bg-gray-300"
      >
        <h3
          class="align-middle text-dark break-words"
        >
          {{ song.album.title }}
        </h3>
      </div>
      <h2
        class="font-source-serif-pro font-semibold text-red text-xl 2xl:text-3xl px-4 group-hover:text-gray-900 group-hover:underline  break-words"
      >
        {{ song.title }}
      </h2>
      <p class="settlement text-semigray  2xl:text-xl">
        <span
          v-for="(settlement, index2) in song.settlements"
          :key="$unique() + index2"
        >{{
          index2 + 1 == song.settlements.length
            ? settlement.name
            : settlement.name + ","
        }}</span>
        <span
          v-if="(song.settlements && song.settlements.length) && (song.regions && song.regions.length)"
        >&#8212;</span>
        <span
          v-for="(region, index3) in song.regions"
          :key="$unique() + index3"
        >
          {{
            index3 + 1 == song.regions.length
              ? region.name
              : region.name + ","
          }}
        </span>
      </p>
      <p class="performer text-semigray mt-2 mb-[20px] flex-grow  2xl:text-xl">
        <span
          v-for="(performer, index4) in song.performers"
          :key="$unique() + index4"
        >
          {{
            index4 + 1 == song.performers.length
              ? performer.name
              : performer.name + ","
          }}
        </span>
      </p>
      <!-- todo majd atirni a feltetelt-->
      <div
        v-if="song.lyrics && song.lyrics.length > 0"
        class="block"
      >
        <nuxt-link
          :to="'/mediatar/felvetelek/' + song.id + '/' + song.slug"
          title="dalszöveg"
        >
          <img
       
            src="/img/lyric_icon.svg"
            class="mx-auto"
            alt=""
          > 
        </nuxt-link>
      </div>
      
      <slot />
    </nuxt-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RePictureSet from '~/components/rxt/recomponent/RePictureSet.vue'

export default {
    components: { 
    RePictureSet,
  },
  props: {
    song: {
      type: Object,
      required: true
    },
    index:{
        required:true
    }
  },
  computed: {
    ...mapState(['isRadioPlaying']),
  },
  methods:{
    switchRadioStatus() {
      this.$store.commit('setIsRadioPlaying', !this.isRadioPlaying)
    },
    time(milliseconds) {
      if (!milliseconds) {
        return "";
      }

      return this.$moment.unix(milliseconds).format("HH:mm");
    },
  }
};
</script>
